<template>
<div class="case-list">
<ul>
  <li v-for="item in caseList" :key="item.id"  @click="caseMore(item.id)">
    <div class="case-item">
      <div class="case-title">
        <p>{{ item.title }}</p>
      </div>
      <img :src="item.list_image">
    </div>
  </li>

</ul>
</div>
</template>

<script>
export default {
  name: "caseList",
  props: {
    caseList: {
      default() {
        return []
      }
    }
  },
  methods:{
    caseMore(id) {
      this.$router.push('/caseMore/' + id)//传递id
    }
  }
}
</script>

<style scoped>

.case-list ul {
  display: flex;
  flex-wrap: wrap;
}
.case-list ul li{
  width: 33%;
  padding:0 10px;
  margin-top: 50px;
  cursor: pointer;
}
.case-item{
  position: relative;
  overflow: hidden;
}

.case-item img {
  width: 100%;
  display: block;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
}

.case-list ul li:hover .case-item img {
  transform: scale(1.2) rotateZ(-6deg);
  -moz-transform: scale(1.2) rotateZ(-6deg);
  -webkit-transform: scale(1.2) rotateZ(-6deg);
  -ms-transform: scale(1.2) rotateZ(-6deg);
}

.case-title{
  position: absolute;
  width: 100%;
  height: 44px;
  left: 0;
  bottom:0;
  background: rgba(38, 74, 159, 0.7);
  z-index: 3;
}

.case-title p{
  text-align: center;
  line-height: 44px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  color: #fff;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .case-list ul li{
    width: 100%;
    padding: 0;
    margin-top: 20px;
  }

}

</style>