<template>
<div class="news-list">
  <ul>
    <li v-for="item in news" :key="item.id"  @click="newsMore(item.id)">
      <div class="news-img">
        <img :src="item.list_image">
      </div>
      <div class="news-txt">
        <h3>{{ item.title}}</h3>
        <p><span>{{ item.date }}</span></p>
        <p>{{item.description}}</p>
      </div>
    </li>
  </ul>

</div>
</template>

<script>
export default {
  name: "news",
  props: {
    news: {
      default() {
        return []
      }
    }
   },
  methods:{
    newsMore(id) {
      this.$router.push('/newsMore/' + id)//传递id
    }
  }
}
</script>

<style scoped>
.news-list ul li{
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.news-img{
  width: 380px;
  margin-right: 20px;
  overflow: hidden;
}

.news-img img{
  width: 380px;
  display: block;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
}
.news-list ul li:hover .news-img img{
  transform: scale(1.2) rotateZ(-6deg);
  -moz-transform: scale(1.2) rotateZ(-6deg);
  -webkit-transform: scale(1.2) rotateZ(-6deg);
  -ms-transform: scale(1.2) rotateZ(-6deg);
}

.news-txt{
  width: calc(100% - 400px);
  color: #000;
}

.news-txt h3{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-txt p{
  color: #666;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow:hidden;
  -webkit-box-orient: vertical;
}

.news-txt span{
  padding: 10px 0;
  display: block;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .news-list ul li{
    flex-wrap: wrap;
    margin-top: 20px
  }
  .news-img{
    width: 100%;
    margin-right: 0;
  }
  .news-txt {
    width:100%;
    margin-top: 10px;
  }

}


</style>