<template>
<div>
  <div class="page-banner">
    <img v-lazy="banner" class="img-center">
  </div>
  <div class="container">
    <div class="padding-80" id="news">

      <div class="common-title">
        <h1>{{ $t("menu.news") }}</h1>
        <div class="common-line"></div>
      </div>
     <news :news="news"/>

      <div class="common-title padding-t-80" id="case">
        <h1>{{ $t("menu.case") }}</h1>
        <div class="common-line"></div>
      </div>
      <case-list :caseList="caseList"/>

    </div>
  </div>
</div>
</template>

<script>
import news from "@/views/solutionDetail/news";
import caseList from "@/views/solutionDetail/caseList";

import {getSolutions} from "@/network/main";

export default {
  name: "Solution",
  components:{
    news,
    caseList
  },
  data() {
    return{
      banner: {},
      news:[],
      caseList:[],
    }
  },
  created(){
    //创建时执行跳转锚点位置。如果导航也跳b页面注意冲突
    // this.$nextTick(() => {
    //   this.getlocal();
    // });

    // console.log( document.getElementsByClassNameByClassName("container"))
    // console.log(document.getElementById('#news'))

    //data
    getSolutions().then(res => {
      this.banner = res.data.data.banner;
      this.news = res.data.data.news;
      this.caseList = res.data.data.case_studies;
    })

  },

  mounted() {
    //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.querySelector(selectId);
      //如果对应id存在，就跳转
      // console.log(selectId,toElement)
      if (toElement !== null) {
       // console.log(toElement, "toElement");
        setTimeout(function() {
          toElement.scrollIntoView({
            behavior: "smooth"
          });

        }, 1000);

      }
  },

 methods: {
   //  getlocal() {
   //   //找到锚点id
   //   let selectId = localStorage.getItem("toId");
   //   let toElement = document.getElementById(selectId);
   //   //如果对应id存在，就跳转
   //   if (selectId) {
   //     console.log(toElement, "toElement");
   //     setTimeout(function() {
   //       toElement.scrollIntoView(true);
   //     }, 100);
   //   }
   //
   // },
 },
  deactivated() {
    localStorage.setItem("toId", "");
  },
}
</script>

<style scoped>

</style>